import './../../custom.scss'
import './intention.scss'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import currencyFormatter from 'currency-formatter'
import jwt from 'jsonwebtoken'
import { useStyles } from './intention.style'
import Terms from '../../components/terms/terms'
import { withTheme } from '@material-ui/styles'
import Ticket from '../../components/ticket/ticket'
import IdentificationForm from '../../components/forms/identification/identificationForm'
import PaymentForm from '../../components/forms/payment/paymentForm'
import { brandsAvailable } from '../../utils/brands-availables'
import { checkoutService } from '../../services/checkout.service'
import { intentErrors } from '../../utils/intent-errors.util'
import { cardTypes } from '../../utils/cardType.util'
import { gate2allService } from '../../services/gate2all.service'
import { gate2allAsyncService } from '../../services/gate2all-async.service'
import { brandsUtils } from '../../utils/brands-api.util'
import { transactionStatus } from '../../utils/transaction-status.util'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import StepperWrapped from '../../components/stepper/stepperWrapped'
import { dateUtil } from '../../utils/date-util'
import { themeService } from '../../services/theme.service'
import Loading from '../../components/loading/loading'
import PaymentMethod from '../../components/paymentMethod/paymentMethod'
import PreviewSlip from '../../components/previewSlip/previewSlip'
import { paymentMethods } from '../../constants/paymentMethod'
import CardTile from '../../components/cardTile/cardTile';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ConfirmDialog from '../../components/confirmDialog/confirmDialog';
import { kondutoUtil } from '../../utils/konduto.utils';
import PixStep from '../../components/pix-step/pix-step';
import { paygoCheckoutUtil } from '../../utils/paygo-checkout.utils';
import { cybersourceUtil } from '../../utils/cybersource.utils';
import { deviceUtil } from '../../utils/device.utils';
import {WebsocketService} from '../../services/websocket.service';

const webSocketSubscribeTransaction = '/user/topic/transaction';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

function getSteps() {
    return ['Identificação', 'Pagamento']
}

function Intention(props) {

    const theme = props.theme
    const classes = useStyles(theme)()

    const transactionId = props.match.params.token

    const [activeStep, setActiveStep] = React.useState(0)
    const [intention, setIntention] = React.useState(null)
    const [accessToken, setAccessToken] = React.useState(null)
    const [error, setError] = React.useState(false)
    const [errorStatus, setErrorStatus] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [loadingPayment, setLoadingPayment] = React.useState(false)
    const [identification, setIdentification] = React.useState(null)
    const [transactionResult, setTransactionResult] = React.useState(null)
    const [acceptedTerms, setAcceptedTerms] = React.useState(false)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [infoSnackbar, setInfoSnackbar] = React.useState('')
    const [errorTransaction, setErrorTransaction] = React.useState('')
    const [loadedTheme, setLoadedTheme] = React.useState(false)
    const [paymentMethodSelected, setPaymentMethodSelected] = React.useState(null)
    const [confirmRenewSession, setConfirmRenewSession] = React.useState(false)
    const [idTimeoutDialog, setIdTimeoutDialog] = React.useState(0)
    const [idTimeoutAutoCancel, setIdTimeoutAutoCancel] = React.useState(0)
    const [visitorId, setVisitorId] = React.useState(null)
    const [pixResume, setPixResume] = React.useState(null)
    const [breakPixSocket, setBreakPixSocket] = React.useState(false)
    const [steps, setSteps] = React.useState(getSteps())
    const [encryptionKey, setEncryptionKey] = React.useState(null)
    const [shouldEncryptCard, setShouldEncryptCard] = React.useState(false)
    const [redirectUrl, setRedirectUrl] = React.useState(null)
    const [allowManualRetry, setAllowManualRetry] = React.useState(false)
    const [client] = React.useState(WebsocketService.getInstance(transactionId))
    const [forceDisconnect, setForceDisconnect] = React.useState(false)
    const [timeoutWebSocket, setTimeoutWebSocket] = React.useState(false)
    const [checkTransactionInService, setCheckTransactionInService] = React.useState(false)
    const [hasErrorInTransaction, setHasErrorInTransaction] = React.useState(false)
    const [isFallbackFromWebSocket, setIsFallbackFromWebSocket] = React.useState(false)

    // carrega a transação com o api-service
    React.useEffect(() => {
        if (transactionId) {
            // verifica se existe
            checkoutService.authorize(transactionId).then(data => {
                // consulta os dados da intenção
                checkoutService.getIntention(transactionId, data['access_token']).then(res => {
                    if (res.data.fraudAnalysis && res.data.fraudAnalysis.provider &&
                        res.data.fraudAnalysis.provider === 'CYBERSOURCE') {
                        cybersourceUtil.loadLibraryCybersource(res.data.tokenIntention)
                            .catch(err => console.error(err))
                    } else if (res.data.fraudAnalysis && res.data.fraudAnalysis.publicKey) {
                        try {
                            kondutoUtil.loadKondutoLibAsync(res.data.fraudAnalysis.publicKey, () => kondutoUtil.getVisitorID(setVisitorId))
                        } catch (e) {
                            console.error(e)
                        }
                    }

                    if (res.headers.authorization) {
                        const intentionResult = res.data
                        if (!intentionResult['isSub']) {
                            setAcceptedTerms(true);
                        }
                        setIntention(intentionResult)
                        setAccessToken(res.headers.authorization)
                        setRedirectUrl(res.data.redirectUrl)
                    } else {
                        // verifica se a transação tem algum estado de retorno
                        if (res.data.status === 2) {
                            const transaction = res.data.transaction
                            if (transaction.payment && transaction.payment.pix) {
                                if (transaction.status !== 0 && transaction.status !== 1) {
                                    createTransactionResultWhenStatusNotIs0Or1(res, transaction)
                                    if (transaction.status == 6 && res.data.redirectUrl) {
                                        redirectTo(res.data.redirectUrl)
                                    }
                                } else {
                                    const pixResume = {
                                        status: transaction.status,
                                        qrCode: transaction.payment.pix.qrCode
                                    }
                                    setIntention({
                                        client: res.data.client,
                                        referenceId: res.data.referenceId,
                                        brands: [],
                                        amount: transaction.amount,
                                        formattedAmount: transaction.formattedAmount,
                                        urlStyleCheckout: res.data.urlStyleCheckout,
                                        paymentMethods: [paymentMethods.PIX.value],
                                    })
                                    setSteps(['', '', 'Pix'])
                                    setActiveStep(2)
                                    setPaymentMethodSelected(paymentMethods.PIX.value)
                                    setAcceptedTerms(true)
                                    setRedirectUrl(res.data.redirectUrl)
                                    setPixResume(pixResume)
                                }

                            } else {
                                if ((transaction.status === 0 || transaction.status === 1) && transaction.payment.electronicTransfer && transaction.payment.electronicTransfer.url) {
                                    window.open(transaction.payment.electronicTransfer.url, '_top')
                                }
                                if ((transaction.status === 0 || transaction.status === 1) && transaction.payment.card
                                        && transaction.payment.card.authenticationUrl) {
                                    redirectTo(transaction.payment.card.authenticationUrl)
                                }
                                createTransactionResultWhenStatusNotIs0Or1(res, transaction)
                            }
                        } else {
                            configError(res, res.data.status)
                        }
                    }

                    if (res.data.payment && res.data.payment.card && (res.data.status === 0 || res.data.status === 1)) {
                        setShouldEncryptCard(true);
                        paygoCheckoutUtil.loadLibraryPaygoGateway()
                            .then(() => paygoCheckoutUtil.generateEncryptionKey(setEncryptionKey))
                            .catch(err => console.error(err))
                    }

                    if (res.data.paymentMethods?.length === 0 && res.data.status === 1) {
                        configError(res, 4)
                    }

                    setLoading(false)
                }).catch(err => {
                    // processa erro ao tentar pegar as informações da intenção
                    setError(true)
                    if (err.response && err.response.data) {
                        setErrorStatus(err.response.data.status)
                        setIntention({
                            client: err.response.data.client,
                            brands: [],
                            urlStyleCheckout: err.response.data.urlStyleCheckout
                        })
                    } else {
                        setLoadedTheme(true)
                        setErrorStatus(-1)
                    }
                    setLoading(false)
                })
            }).catch(err => {
                // não encontrou nenhuma intenção
                setLoadedTheme(true)
                setError(true)
                setErrorStatus(-1)
                setLoading(false)
            })
        }
    }, [transactionId])

    // verifica se existe tema para esse cliente, caso contrário mantém o default
    React.useEffect(() => {
        if (!loadedTheme && intention) {
            if (intention.urlStyleCheckout) {
                themeService.getTheme(intention.urlStyleCheckout).then(data => {
                    setLoadedTheme(true)
                    props.setTheme(data)
                }).catch(err => {
                    console.log(err)
                    setLoadedTheme(true)
                })
            } else {
                setLoadedTheme(true)
            }
        }
    }, [intention, loadedTheme])

    const redirectTo = (redirectUrl) => {
        setTimeout(() => {
            setSnackbarOpen(true)
            setInfoSnackbar(`Você está sendo redirecionado para: ${redirectUrl}`)
        }, 1000)
        setTimeout(() => {
            window.open(redirectUrl, '_top')
        }, 4000)
    }

    const disconnectFromWebSocket = () => {
        return new Promise((resolve, reject) => {
            if (client.isConnected) {
                client.disconnect()
            }
            resolve()
        })
    }

    React.useEffect(() => {
        if (forceDisconnect) {
            disconnectFromWebSocket()
            setForceDisconnect(false)
        }
    }, [forceDisconnect])

    React.useEffect(() => {
        if (timeoutWebSocket) {
            const timeToDisconnect = 16000
            setTimeout(() => {
                setCheckTransactionInService(true)
            }, timeToDisconnect)
        }
    }, [timeoutWebSocket])

    React.useEffect(() => {
        if (checkTransactionInService && !transactionResult && !hasErrorInTransaction) {
            setForceDisconnect(true)
            setTimeoutWebSocket(false)
            getIntention()
        }
        if (hasErrorInTransaction) {
            setHasErrorInTransaction(false)
        }
    }, [checkTransactionInService])

    const paymentMethodSelectedIsCard = () => {
        return paymentMethodSelected && paymentMethodSelected == paymentMethods.CARD.value
    }

    const mustConnectToWebSocket = () => {
        return paymentMethodSelectedIsCard() && activeStep == 1 && !client.isConnected
    }

    React.useEffect(() => {
        if (mustConnectToWebSocket()) {
            connectInWebSocket()
        }
    }, [activeStep, paymentMethodSelected])

    const isIntentionWithCardTypeDebit = intention => {
        return intention != null && intention.cardType != null
            && intention.cardType == 2
    }

    React.useEffect(() => {
        if (acceptedTerms && isIntentionWithCardTypeDebit(intention)) {
            setActiveStep(1)
        }
    }, [acceptedTerms, intention])

    const transactionAllowsManualRetry = (transactionResult) => {
        return transactionResult.allowsManualRetry;
    }

    // define no estado o valor retornado da transação
    const updateTransactionResult = transactionResult => {
        if (transactionAllowsManualRetry(transactionResult)) {
            setAllowManualRetry(true)
            transactionResult.status = transactionResult.status * -1
        }
        setTransactionResult(transactionResult)
        setForceDisconnect(true)
        setLoadingPayment(false)
        setLoading(false)
        handleNextStep()
    }

    // requisita um novo accessToken
    const renewAccessToken = (err) => {
        return new Promise((resolve, reject) => {
            checkoutService.authorize(transactionId).then(data => {
                setAccessToken(data['access_token']);
                if (err && err.response) {
                    if ((parseInt(err.response.status) / 100) === 5) {
                        setErrorTransaction('Problemas na comunicação com o servidor.')
                        setSnackbarOpen(true)
                    } else {
                        if (err.response.data) {
                            setErrorTransaction(err.response.data.error.message)
                            setSnackbarOpen(true)
                        }
                    }
                }
                resolve(data['access_token'])
            }).catch(() => {
                setTransactionResult({ status: 0 })
                reject()
            })
        })
    }

    const setIntentionError = (errorStatus = -1) => {
        setLoading(false)
        setIsFallbackFromWebSocket(true)
        setErrorStatus(errorStatus)
        setError(true)
    }

    const getIntention = () => {
        renewAccessToken().then(accessToken => {
            checkoutService.getIntention(transactionId, accessToken).then(res => {
                const statusAwaitingPayment = 0
                if (res.data) {
                    if (res.data.transaction) {
                        const transaction = res.data.transaction
                        if (isSubAuthorizedCardTransaction(res.data, transaction)) {
                            transaction.status = '5.1'
                        }
                        updateTransactionResult(transaction)
                    } else {
                        setIntentionError(statusAwaitingPayment)
                    }
                } else {
                    setIntentionError(statusAwaitingPayment)
                }
            }).catch(error => {
                setIntentionError()
            })
        }).catch(error => {
            setIntentionError()
        })
    }

    const isSubAuthorizedCardTransaction = (intention, transaction) => {
        return intention && intention.isSub && transaction.payment
            && transaction.payment.card && transaction.status === 5
    }

    const createTransactionResultWhenStatusNotIs0Or1 = (res, transaction) => {
        setIntention({
            client: res.data.client,
            referenceId: res.data.referenceId,
            brands: [],
            amount: transaction.amount,
            formattedAmount: transaction.formattedAmount,
            urlStyleCheckout: res.data.urlStyleCheckout
        })
        if (isSubAuthorizedCardTransaction(res.data, transaction)) {
            transaction.status = '5.1'
        }
        setTransactionResult(transaction)
        setAcceptedTerms(true)
    }

    const configError = (res, status) => {
        setError(true)
        const data = { ...res.data, brands: [] }
        setIntention(data)
        setErrorStatus(status)
    }

    // requisita novo token e renova a chave de criptografia após confirmação do usuário
    const onConfirmRenewSession = () => {
        clearTimeout(idTimeoutAutoCancel)
        clearTimeout(idTimeoutDialog)
        setConfirmRenewSession(false)
        renewAccessToken(null)
        if (shouldEncryptCard) {
            renewEncryptionKey()
        }
    }

    const renewSessionForManualRetry = () => {
        return new Promise((resolve, reject) => {
            clearTimeout(idTimeoutAutoCancel)
            clearTimeout(idTimeoutDialog)
            setConfirmRenewSession(false)
            if (shouldEncryptCard) {
                renewEncryptionKey()
            }
            renewAccessTokenForManualRetry()
                .then((accessTokenForManualRetry) => { resolve(accessTokenForManualRetry) })
                .catch(err => reject(err));
        });
    }

    // altera o transactionResult para não ser mais possível realizar o pagamento
    const onCancelRenewSession = () => {
        clearTimeout(idTimeoutAutoCancel)
        clearTimeout(idTimeoutDialog)
        setAcceptedTerms(true)
        setConfirmRenewSession(false)
        setTransactionResult({ status: '-2' })
        setAllowManualRetry(false)
        setForceDisconnect(true)
    }

    // cria o dialog de confirmação de renovar sessão
    React.useEffect(() => {
        if (!transactionResult && !error && accessToken) {
            const token = jwt.decode(accessToken)
            const myDate = new Date(token.exp * 1000);
            const today = new Date()
            // const myDate = new Date(today.getTime() + (60000 * 3))
            const difference = myDate.getTime() - today.getTime()
            const threeMinutes = 60000 * 3
            const timeToOpen = difference - threeMinutes
            const idTimeoutDialog = setTimeout(() => {
                setConfirmRenewSession(true)
                const idAutoCancel = setTimeout(() => {
                    onCancelRenewSession()
                }, threeMinutes - 1000)
                setIdTimeoutAutoCancel(idAutoCancel)
            }, timeToOpen)
            setIdTimeoutDialog(idTimeoutDialog)
        }
    }, [accessToken, transactionResult, error])

    // mostra o loading se não tiver processado as informações
    if (loading || !loadedTheme) {
        return <Loading fullWidth isPaymentLoading={loadingPayment} />
    }

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    // processa os dados vindo da etapa de identificação
    const submitIdentification = identificationData => {
        setIdentification(identificationData)
        handleNextStep()
        if (paymentMethodSelected === 'bankSlip') {
            submitPaymentBankSlip(identificationData)
        }

        if (paymentMethodSelected === 'bankTransfer') {
            submitPaymentBankTransfer(identificationData)
        }
    }

    // fecha a notificação
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setSnackbarOpen(false)
    }

    // cria objeto de transação a ser enviado para o gate2all
    const createTransactionWithIdentification = identificationData => {
        return {
            userAgent: navigator.userAgent,
            transactionId: props.match.params.token,
            referenceId: intention.referenceId,
            amount: intention.amount,
            description: intention.description,
            customer: {
                name: identificationData.name,
                document: identificationData.document.replace(/(\d*)([.\-/])/g, `$1`),
                email: identificationData.email,
                phoneNumber: identificationData.phone.replace(/(\d*)([ ()])/g, `$1`),
                address: {
                    address: identificationData.address.address,
                    number: identificationData.address.number,
                    district: identificationData.address.neighborhood,
                    zipcode: identificationData.cep.replace(/(\d*)([-])/g, `$1`),
                    city: identificationData.address.city,
                    state: identificationData.address.state,
                    complement: identificationData.address.complement
                }
            },
            fraudAnalysis: {
                analyze: (intention.fraudAnalysis && intention.fraudAnalysis.analyze),
                visitorId: visitorId
            },
            device: deviceUtil.getDevice()
        }
    }

    const createTransactionWithoutIdentification = () => {
        return {
            userAgent: navigator.userAgent,
            transactionId: props.match.params.token,
            referenceId: intention.referenceId,
            amount: intention.amount,
            description: intention.description,
            fraudAnalysis: {
                analyze: (intention.fraudAnalysis && intention.fraudAnalysis.analyze),
                visitorId: visitorId
            },
            device: deviceUtil.getDevice()
        }
    }

    // envia a transação do tipo boleto
    const submitPaymentBankSlip = identificationData => {
        const transaction = createTransactionWithIdentification(identificationData)
        const bankSlip = {
            ...intention.payment.bankSlip,
            expirationDate: intention.payment.bankSlip.expirationDate.replace(/(\d{2})(\/)(\d{2})(\/)(\d{4})/gm, `$5-$3-$1`)
        }
        const finalTransaction = {
            ...transaction, payment: {
                bankSlip: bankSlip
            }
        }

        sendTransactionWithCallback(finalTransaction, (data) => {
            if (data.payment.bankSlip.url && !data.payment.bankSlip.barCode) {
                window.open(data.payment.bankSlip.url, '_top')
            } else {
                updateTransactionResult(data)
            }
        })
    }

    // envia a transação do tipo transferência
    const submitPaymentBankTransfer = identificationData => {
        const transaction = createTransactionWithIdentification(identificationData)
        const finalTransaction = {
            ...transaction, payment: {
                electronicTransfer: intention.payment.electronicTransfer
            }
        }
        sendTransactionWithCallback(finalTransaction, data => {
            if (data.payment.electronicTransfer.url) {
                window.open(data.payment.electronicTransfer.url, '_top')
            } else {
                updateTransactionResult(data)
            }
        })
    }

    const createPaymentCard = paymentData => {
        return Promise.resolve({
            card: {
                type: intention.payment.card.type === 0 ? cardTypes[paymentData.card.cardType] : intention.payment.card.type,
                softDescriptor: intention.payment.card.softDescriptor,
                installments: paymentData.card.installments,
                cardInfo: {
                    number: paymentData.card.number,
                    expirationMonth: paymentData.card.expiry.substr(0, 2),
                    expirationYear: new Date().getFullYear().toString().substr(0, 2)
                        .concat(paymentData.card.expiry.substr(3, 2)),
                    cvv: paymentData.card.cvv,
                    brand: brandsUtils.apiBrandsByPass[paymentData.card.brand],
                    holderName: paymentData.card.name
                }
            }
        })
    }

    const generateCardHash = payment => {
        return paygoCheckoutUtil.configureEncryptionKey(encryptionKey)
            .then(() => paygoCheckoutUtil.generateCardHash(payment.card.cardInfo))
            .then(cardHash => {
                payment.card.cardInfo = {
                    cardHash: cardHash
                }
            })
            .catch(err => console.error(err))
            .then(() => payment)
    }

    // envia a transação do tipo cartão
    const submitPaymentCard = paymentData => {
        setLoadingPayment(true)
        setLoading(true)
        createPaymentCard(paymentData)
            .then(payment => generateCardHash(payment))
            .then(payment => {
                const transaction = payment.card.type == 2 ? createTransactionWithoutIdentification()
                    : createTransactionWithIdentification(identification)
                sendTransactionAsyncWithCallback({ ...transaction, payment }, data => {
                    // OnFinish configurado direto no onConnectInWebSocket.
                    if (data.status === 0 && data.payment.card.authenticate === 1) {
                        window.open(data.payment.card['authenticationURL'], '_top')
                    } else {
                        updateTransactionResult(data)
                    }
                })
            })
    }

    const submitPaymentPix = (accessTokenForManualRetry = null) => {
        const transaction = {
            userAgent: navigator.userAgent,
            transactionId: props.match.params.token,
            referenceId: intention.referenceId,
            amount: intention.amount,
            description: intention.description,
            payment: {
                pix: { ...intention.payment.pix }
            }
        }
        setSteps(['', '', 'Pix'])
        setActiveStep(2)
        gate2allService.transaction(transaction, accessTokenForManualRetry != null ?
                accessTokenForManualRetry : accessToken).then(data => {
            setBreakPixSocket(false)
            if (data && data.payment && data.payment.pix && data.payment.pix.qrCode && data.status === 1) {
                setPixResume({ status: data.status, qrCode: data.payment.pix.qrCode })
            }
            if (data.status !== 0 && data.status !== 1) {
                updateTransactionResult(data)
            }
        }).catch(err => {
            setBreakPixSocket(true)
            console.error(err)
        })
    }

    const sendTransactionWithCallback = (transaction, onFinish) => {
        setLoadingPayment(true)
        setLoading(true)
        gate2allService.transaction(transaction, accessToken).then(data => {
            if (data.status !== 0 && data.status !== 1 && data['redirectUrl']) {
                updateTransactionResult(data)
                setTimeout(() => {
                    setSnackbarOpen(true)
                    setInfoSnackbar(`Você está sendo redirecionado para: ${data['redirectUrl']}`)
                }, 1000)
                setTimeout(() => {
                    const redirectUrl = `${data['redirectUrl']}${data['redirectUrl'].includes('?') ? '&' : '?'}transactionId=${transaction.transactionId}&referenceId=${transaction.referenceId}`
                    window.open(redirectUrl, '_top')
                }, 4000)
            } else {
                onFinish(data)
            }
        }).catch(error => {
            setLoadingPayment(false)
            setLoading(false)
            setErrorTransaction('Não foi possível realizar o pagamento, tente novamente mais tarde!')
            renewAccessToken(error)
            if (shouldEncryptCard) {
                renewEncryptionKey()
            }
        })
    }

    // TODO - Ajustar o tratamento do onFinish.
    const sendTransactionAsyncWithCallback = (transaction, onFinish) => {
        setLoadingPayment(true)
        setLoading(true)
        setTimeoutWebSocket(true)
        gate2allAsyncService.transaction(transaction, accessToken).then(data => {
            if (!data && !data.status) {
                setForceDisconnect(true)
                throw Error("Não foi possível realizar o pagamento, tente novamente mais tarde!");
            }
        }).catch(error => {
            setLoadingPayment(false)
            setLoading(false)
            setErrorTransaction('Não foi possível realizar o pagamento, tente novamente mais tarde!')
            setHasErrorInTransaction(true)
            renewAccessToken(error)
            if (shouldEncryptCard) {
                renewEncryptionKey()
            }
        })
    }

    const connectInWebSocket = () => {
        return new Promise((resolve, reject) => {
            if (!client.isConnected) {
                client.connect(
                    onConnectInWebSocket,
                    () => {},
                    () => {},
                )
            }
            resolve()
        })
    }

    const onConnectInWebSocket = () => {
        return new Promise((resolve, reject) => {
            const subscribe = client.subscribe(
                webSocketSubscribeTransaction,
                message => {
                    const data = JSON.parse(message.body)
                    if (data) {
                        if (data.status === 5 && intention.isSub) {
                            console.warn('Ignore message with temporary status');
                        } else if (data.status !== 0 && data.status !== 1 && redirectUrl) {
                            updateTransactionResult(data)
                            setTimeout(() => {
                                setSnackbarOpen(true)
                                setInfoSnackbar(`Você está sendo redirecionado para: ${redirectUrl}`)
                            }, 1000)
                            setTimeout(() => {
                                const redirectUrlWithParameters = `${redirectUrl}${redirectUrl.includes('?') ? '&' : '?'}transactionId=${transactionId}&referenceId=${intention.referenceId}`
                                window.open(redirectUrlWithParameters, '_top')
                            }, 4000)
                        } else if (data.status === 0 && data.authenticationURL) {
                            window.open(data.authenticationURL, '_top')
                        } else {
                            updateTransactionResult(data)
                        }
                    } else {
                        console.warn('Received empty message', message);
                    }
                }
            )
            if (subscribe) {
                resolve()
            } else {
                reject()
            }
        })
    }

    // Requisita um novo accessToken a partir do fluxo de retentativa manual
    const renewAccessTokenForManualRetry = () => {
        return new Promise((resolve, reject) => {
            checkoutService.authorize(transactionId)
                .then(data => { resolve(data['access_token']) })
                .catch(err => reject(err));
        });
    }

    const redirectUrlIsDefined = () => {
        return typeof redirectUrl !== 'undefined' && redirectUrl != null
    }

    const redirectToIntentionUrl = () => {
        if (redirectUrlIsDefined()) {
            setTimeout(() => {
                setSnackbarOpen(true)
                setInfoSnackbar(`Você está sendo redirecionado para: ${redirectUrl}`)
            }, 1000)
            setTimeout(() => {
                window.open(redirectUrl, '_top')
            }, 4000)
        }
    }

    // requisita uma nova chave de criptografia
    const renewEncryptionKey = () => {
        paygoCheckoutUtil.generateEncryptionKey(setEncryptionKey)
            .catch(err => console.error(err))
    }

    // verifica o ticket pode ser exibido
    const showTicket = () => {
        if (transactionResult && transactionResult.payment && transactionResult.payment.bankSlip) {
            return !transactionResult.payment.bankSlip.barCode
        }

        return true
    }

    // verifica se etapa de identicação é obrigatório
    const validIsRequired = () => {
        if (paymentMethodSelected === paymentMethods.PIX.value || (paymentMethodSelected === paymentMethods.CARD.value
            && intention.cardType == 2)) {
            return false
        }

        if (paymentMethodSelected === paymentMethods.BANK_SLIP.value || paymentMethodSelected === paymentMethods.BANK_TRANSFER.value) {
            return true
        }

        return intention.fraudAnalysis && intention.fraudAnalysis.analyze;
    }

    // obtém os inputs que precisam ser obrigatórios no formulário de identificação
    const getInputsRequired = () => {
        let inputs = []
        if (!validIsRequired()) {
            return inputs
        }

        if (paymentMethodSelected === paymentMethods.BANK_SLIP.value || paymentMethodSelected === paymentMethods.BANK_TRANSFER.value) {
            inputs = inputs.concat(['name', 'document', 'cep'])
            if (intention['isSub']) {
                inputs = inputs.concat(['email'])
            }

            return inputs
        }

        return ['name', 'document', 'email', 'phone', 'cep']
    }

    // altera o estado do metódo de pagamento selecionado
    const onSelectedPaymentMethod = selected => {
        let resultSelected = selected
        if (selected === paymentMethods.VIRTUAL_DEBIT_ELO.value) {
            resultSelected = paymentMethods.CARD.value
            setIntention({ ...intention, brands: [{ name: 'ELO_CAIXA' }] })
        }
        if (selected === paymentMethods.PIX.value) {
            submitPaymentPix()
        }
        setPaymentMethodSelected(resultSelected)
    }

    const brandsAvailableReturned = brandsAvailable(intention ? intention.brands : [])

    // obtem o componente de acordo com a etapa
    const getContentByStep = step => {
        if (steps.includes('Pix')) {
            return <PixStep transactionId={transactionId} onResult={(pix) => {
                setTransactionResult({ status: pix.status }); redirectToIntentionUrl()
            }} forceDisconnect={breakPixSocket} pixInitialData={pixResume} />
        }

        if (step === 0) {
            return (<IdentificationForm submit={submitIdentification} identification={identification}
                customer={intention.customer} loadingPayment={loadingPayment}
                inputsRequired={getInputsRequired()}
                paymentMethodSelected={paymentMethodSelected}
                isRequired={validIsRequired()} />)
        } else if (step === 1 && paymentMethodSelected === paymentMethods.CARD.value) {
            return <PaymentForm submit={submitPaymentCard} brands={brandsAvailableReturned} intention={intention}
                loading={loadingPayment} cancelPayment={validIsRequired() ? handleBackStep : null} />
        } else {
            return null
        }
    }

    const tryToPayByCard = () => {
        setTransactionResult(null)
        setAllowManualRetry(false)
        onConfirmRenewSession()
        setPaymentMethodSelected(paymentMethods.CARD.value)
        if (paymentMethodSelectedIsCard()) {
            setActiveStep(1)
        } else {
            setSteps(getSteps())
            setActiveStep(0)
        }
        if (intention.isSub) {
            setAcceptedTerms(true);
        }
    }

    const tryToPayByPix = () => {
        setTransactionResult(null)
        setAllowManualRetry(false)
        setPaymentMethodSelected(paymentMethods.PIX.value)
        renewSessionForManualRetry()
            .then(accessTokenForManualRetry => { submitPaymentPix(accessTokenForManualRetry) })
        if (intention.isSub) {
            setAcceptedTerms(true);
        }
    }

    const tryToPayByBankSlip = () => {
        setTransactionResult(null)
        setAllowManualRetry(false)
        onConfirmRenewSession()
        setPaymentMethodSelected(paymentMethods.BANK_SLIP.value)
        setSteps(getSteps())
        setActiveStep(0)
        if (intention.isSub) {
            setAcceptedTerms(true);
        }
    }

    const paymenyMethodIncludes = paymentMethod => {
        return intention['paymentMethods'].includes(paymentMethod)
    }

    let title = error ? `${intentErrors[errorStatus].whatsHappen}` : `Pague R$${currencyFormatter.format(intention.formattedAmount, {
        locale: 'pt-BR',
        symbol: ''
    })} para ${intention.client.tradingName} inserindo as informações abaixo.`

    if (transactionResult) {
        title = transactionStatus[transactionResult.status].title
    }

    const expireInHours = dateUtil.getDateHoursFromExpiry(intention ? intention.expireIn : undefined)
    const expireInDay = dateUtil.getDateFromExpiry(intention ? intention.expireIn : undefined)

    return (
        <div className={classes}>
            <ConfirmDialog onOk={onConfirmRenewSession} visible={confirmRenewSession}
                onCancel={onCancelRenewSession}
                title="Deseja renovar sua sessão?"
                contentText={`Sua sessão vai expirar às 
                           ${accessToken
                        ? new Date(jwt.decode(accessToken).exp * 1000).toLocaleTimeString()
                        : ''
                    }`} />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={errorTransaction ? 'error' : 'info'}>
                    {errorTransaction ? errorTransaction : infoSnackbar}
                </Alert>
            </Snackbar>
            <Grid container spacing={5} justify={'space-between'} className='intetion-content-custom'>
                <Grid item md={5} sm={6} xs={12}>
                    <div className="principal-description">
                        <h1 className={classes.h1}>{title}</h1>
                    </div>
                    {
                        transactionResult &&
                        <p className={classes.p}>{transactionStatus[transactionResult.status].subTitle(intention.client.tradingName)}</p>
                    }
                    {
                        transactionResult && allowManualRetry &&
                        <div className={'payment-method'}>
                            <Grid container alignItems="stretch" direction={'row'} spacing={2}>
                                {
                                    paymenyMethodIncludes(paymentMethods.CARD.value) &&
                                    <Grid item xs={12}>
                                        <CardTile onClick={tryToPayByCard} title="Cartão"
                                            description="Cartão de crédito, débito, ou débito virtual caixa."
                                            icon={<CreditCardIcon/>}
                                            value={paymentMethods.CARD.value}/>
                                    </Grid>
                                }
                                {
                                    paymenyMethodIncludes(paymentMethods.PIX.value) &&
                                    <Grid item xs={12}>
                                        <CardTile onClick={tryToPayByPix} title="Pix"
                                            description="O Pix é uma nova maneira de fazer transferências e pagamentos."
                                            icon={<img src={'/images/pix-icon.png'} alt='pix-icon' className='pix-icon'/>}
                                            value={paymentMethods.PIX.value}/>
                                    </Grid>
                                }
                                {
                                    paymenyMethodIncludes(paymentMethods.BANK_SLIP.value) &&
                                    <Grid item xs={12}>
                                        <CardTile onClick={tryToPayByBankSlip} title="Boleto"
                                            description="Copie o código de barras ou visualize o boleto."
                                            icon={<FileCopyOutlinedIcon/>}
                                            value={paymentMethods.BANK_SLIP.value}/>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    }
                    {
                        !error &&
                        <div>
                            {
                                !transactionResult &&
                                <div>
                                    {
                                        expireInHours && !paymentMethodSelected &&
                                        <Paper>
                                            <Grid container justify="space-between" alignItems="flex-start">
                                                <Grid item xs={2}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <InfoOutlinedIcon color="primary" style={{ marginTop: 20 }} />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item>
                                                            <p style={{ paddingRight: 14 }}>
                                                                <strong>
                                                                    {`Este link é válido até às ${expireInHours} do dia ${expireInDay}.`}
                                                                </strong>
                                                            </p>
                                                        </Grid>
                                                        <Grid item>
                                                            <p style={{ paddingRight: 14, marginTop: 0 }}>
                                                                Passado este período, será necessário solicitar um novo
                                                                link ao
                                                                estabelecimento.
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    }
                                    {
                                        intention['isSub'] &&
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item xs={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Checkbox value={acceptedTerms} disabled={acceptedTerms} checked={acceptedTerms}
                                                        onChange={e => setAcceptedTerms(e.target.checked)} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <p className={classes.p}>Ao utilizar este serviço, declaro que sou maior
                                                de
                                                idade e
                                                    aceito os <a
                                                        className={classes.a}
                                                        rel="noopener noreferrer"
                                                        href="https://openfiles.paygo.com.br/Termo_de_Uso_e_Politica_de_Privacidade_v3.2.pdf"
                                                        target="_blank">termos de uso e condições</a>.</p>
                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            }
                            {
                                !acceptedTerms &&
                                <div style={{ textAlign: 'center' }}>
                                    <p>Você precisa aceitar os termos para continuar.</p>
                                </div>
                            }
                        </div>
                    }
                    {
                        acceptedTerms &&
                        <div>
                            {
                                !error && !transactionResult && intention['paymentMethods'].length && !paymentMethodSelected &&
                                <PaymentMethod onSelected={onSelectedPaymentMethod}
                                    methodsAvailable={intention['paymentMethods']} />
                            }
                            {
                                error && errorStatus !== -1 &&
                                <p className={classes.p}>{intentErrors[errorStatus].whatToDo(intention.client.name)}</p>
                            }
                            <br />
                            <StepperWrapped activeStep={activeStep} steps={steps}
                                visible={activeStep !== 'pix' && !error && !transactionResult && paymentMethodSelected} />
                            <br />
                            {
                                !error && !transactionResult && paymentMethodSelected &&
                                <div>
                                    {
                                        getContentByStep(activeStep)
                                    }
                                    <Terms />
                                </div>
                            }
                        </div>
                    }
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    {
                        showTicket() &&
                        <Ticket brands={brandsAvailableReturned} intention={intention} error={error}
                            errorStatus={errorStatus} finish={transactionResult && true}
                            finishStatus={transactionResult ? transactionResult.status : undefined}
                            descriptionPaymentMethod={
                                (transactionResult && transactionResult.card && (transactionResult.status === 5 || transactionResult.status === 6)) ?
                                    `em ${transactionResult.payment.card.installments}x no cartão de ${transactionResult.payment.card.type === 1 ? 'crédito' : 'débito'}.` :
                                    ''
                            } isFallbackFromWebSocket={isFallbackFromWebSocket} />
                    }
                </Grid>
                {
                    !error && !showTicket() &&
                    <Grid item xs={12}>
                        <PreviewSlip barCode={transactionResult.payment.bankSlip.barCode}
                            urlBankSlip={transactionResult.payment.bankSlip.url} />
                    </Grid>
                }
            </Grid>
        </div>
    )
}

export default withTheme(Intention)
